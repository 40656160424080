<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="1000"
    scrollable
    persistent
  >
    <SentInvoiceForm
      :disabled="$store.getters.loading[`put:api/documents/${editedSentInvoice.id}`]"
      :errors="sentInvoiceValidationErrors"
      :sent-invoice="editedSentInvoice"
      @clear:errors="CLEAR_SENT_INVOICE_VALIDATION_ERRORS"
      @cancel="goToSentInvoicesPage"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import store from '@/store';
import SentInvoiceForm from '@/components/forms/SentInvoiceForm';

export default {
  name: 'EditSentInvoice',

  components: { SentInvoiceForm },

  computed: {
    ...mapState('sentInvoices', [
      'editedSentInvoice',
      'sentInvoiceValidationErrors',
      'sentInvoiceFilterParams',
    ]),
  },

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('sentInvoices/editSentInvoice', +to.params.id)
      .then(() => {
        next();
      })
      .catch(() => {
        next({ name: 'sentInvoices', query: store.state.sentInvoices.sentInvoiceFilterParams });
      });
  },

  methods: {
    ...mapActions('sentInvoices', ['updateSentInvoice']),
    ...mapMutations('sentInvoices', ['CLEAR_SENT_INVOICE_VALIDATION_ERRORS']),

    onSave(sentInvoice) {
      this.updateSentInvoice(sentInvoice).then(() => {
        this.goToSentInvoicesPage();
      });
    },

    goToSentInvoicesPage() {
      this.$router.push({ name: 'sentInvoices', query: this.sentInvoiceFilterParams });
    },
  },
};
</script>
